import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectStock (Vue,filter, search, sorter, page, idalmacen) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idalmacen', idalmacen)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('codigo', search)
        .addILike('descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.unidadesDesde.value) {
      apiFilter.addGTE(filter.unidadesDesde.field, filter.unidadesDesde.value)
    }
    if (filter.unidadesHasta.value) {
      apiFilter.addLTE(filter.unidadesHasta.field, filter.unidadesHasta.value)
    }
    const resp = await Vue.$api.call('stock.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectStockRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idstock', pks)
    const resp = await Vue.$api.call('stock.select', {
      filter: apiFilter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectAlmacen (Vue, idalmacen) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idalmacen', idalmacen)
    const resp = await Vue.$api.call('almacen.select', {
      filter: apiFilter
    })
    return resp.data.result.dataset[0]
  },
}
