<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      :empty-message="emptyMessage"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :page-loaded="pageLoaded"
      pk-name="idstock"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <stock-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <stock-list-legend
      :showing.sync="showingDialogs.legends"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import { get } from 'vuex-pathify'
import Data from './StockListData'
import StockListLegend from './components/StockListLegend'
import StockListItem from './components/StockListItem'

const pageStoreName = 'pagesStockList'

export default {
  components: {
    StockListItem, StockListLegend
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false
      },
      emptyMessage: 'No tienes artículos en stock'
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    usuarioIdalmacen: get('usuario/idalmacen'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      const almacen = await Data.selectAlmacen(this, this.usuarioIdalmacen)
      if (almacen){
        this.title = `Stock (${almacen.descripcion})`
      } else {
        this.title = 'Sin almacén'
        this.emptyMessage = 'No tienes almacén asignado'
      }
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectStock(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.usuarioIdalmacen
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectStockRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idstock')
      } finally {
        this.loadingData = false
      }
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
  }
}
</script>
